import React from 'react';

const Results = () => {
  return (
    <div>Search results grid<br/>
      Work in progress<br/>
      Login to see other routes
    
    </div>
  );
};

export default Results;